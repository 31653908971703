<template>
    <div>
        <transition name="fade">
            <div v-if="login_active == true" class="login-overlay" v-on:click="cancel"></div>
        </transition>
        <transition name="slide">
            <div v-if="login_active == true" class="login-dropdown">
                <div class="outer">
                    <div v-if="verify_active == false" class="inner">
                    <form method="post" action="" @submit.prevent="submit_login">
                        <h2>{{product_data.product_settings.widget.text.login_title}}</h2>
                        <div class="description">{{product_data.product_settings.widget.text.login_description}}</div>
                        <div class="form-input login-form" v-if="product_data.product_settings.general.sso_force !== true">
                            <input class="input-general" type="text" placeholder="your@mail.com" v-model="auth_data.data_mail">
                            <ion-icon name="ios-send" class="login-icon" v-if="loading == false" v-on:click="submit_login"></ion-icon>
                            <img v-if="loading == true" class="login-icon" src="@/assets/media/svg/loading.svg">
                        </div>
                        <div v-else class="login-external">
                            <a :href="product_data.product_settings.general.sso_url" target="_parent" referrerpolicy="no-referrer-when-downgrade">{{ $t("login_via", {product: product_data.product_name}) }}</a>
                        </div>
                        <div class="consent" v-if="product_data.product_settings.widget.legal && product_data.product_settings.widget.legal.enabled && product_data.product_settings.general.sso_force !== true">
                            <label v-if="product_data.product_settings.widget.legal.consent">
                                <input type="checkbox" id="scales" name="scales" v-model="auth_data.consent"/> 
                                <i18n path="consent_explicit" tag="span" for="consent_terms">
                                    <a :href="((product_data.product_settings.widget.legal.custom_terms) ? product_data.product_settings.widget.legal.custom_terms : 'https://' + product_data.product_domain + '/custom/terms' )" target="_blank" referrerpolicy="no-referrer-when-downgrade">{{ $t("consent_terms") }}</a>
                                    <a :href="((product_data.product_settings.widget.legal.custom_privacy) ? product_data.product_settings.widget.legal.custom_privacy : 'https://' + product_data.product_domain + '/custom/privacy' )" target="_blank" referrerpolicy="no-referrer-when-downgrade">{{ $t("consent_privacy") }}</a>
                                </i18n>
                            </label>
                            <label v-else>                    
                                <i18n path="consent_implicit" tag="span" for="consent_terms">
                                    <a :href="((product_data.product_settings.widget.legal.custom_terms) ? product_data.product_settings.widget.legal.custom_terms : 'https://' + product_data.product_domain + '/custom/terms' )" target="_blank" referrerpolicy="no-referrer-when-downgrade">{{ $t("consent_terms") }}</a>
                                    <a :href="((product_data.product_settings.widget.legal.custom_privacy) ? product_data.product_settings.widget.legal.custom_privacy : 'https://' + product_data.product_domain + '/custom/privacy' )" target="_blank" referrerpolicy="no-referrer-when-downgrade">{{ $t("consent_privacy") }}</a>
                                </i18n>
                            </label>
                        </div>
                        <div class="login-skip" v-if="product_settings.general.anonymous && product_data.product_settings.general.sso_force !== true">
                            <a href="javascript:void(0)" v-on:click="skip">{{ $t("login_skip") }}</a>
                        </div>
                    </form>
                    </div>

                    <transition name="fade">
                    <div v-if="verify_active == true" class="inner">
                        <form method="post" action="" @submit.prevent="submit_confirm">
                        <h2>{{product_data.product_settings.widget.text.login_title_con}}</h2>
                        <div class="description">{{product_data.product_settings.widget.text.login_description_con}}</div>
                        <div v-if="loading == false" class="container verify">
                                <input type="number" inputmode="numeric" pattern="[0-9]*" maxlength="1" v-on:keyup="key_input($event)" v-model="auth_data.confirm_1" placeholder="·">
                                <input type="number" inputmode="numeric" pattern="[0-9]*" maxlength="1" v-on:keyup="key_input($event)" v-model="auth_data.confirm_2" placeholder="·">
                                <input type="number" inputmode="numeric" pattern="[0-9]*" maxlength="1" v-on:keyup="key_input($event)" v-model="auth_data.confirm_3" placeholder="·">
                                <input type="number" inputmode="numeric" pattern="[0-9]*" maxlength="1" v-on:keyup="key_input($event)" v-model="auth_data.confirm_4" placeholder="·">
                        </div>
                        <div v-if="loading == true" class="verify">
                            <img src="@/assets/media/svg/loading-light.svg">
                        </div>
                        <input type="submit" class="verify-hidden" />
                        <div class="verify-resend" :class="{active: resend_active}" v-on:click="resend_email">
                            <div class="counter" v-if="!resend_active">{{resend_counter}}</div>
                            <div>Resend email</div>
                        </div>
                        </form>
                    </div>
                    </transition>

                    <ion-icon name="ios-close" class="login-close" v-on:click="cancel"></ion-icon>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {

    name: 'login',


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {

        return {
            loading: false,
            verify_active: false,
            auth_data: {},
            auth_consent: false,
            resend_active: false,
            resend_counter: 60,
            resend_interval: false,
        }

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        login_active() {
            return this.$store.getters.widget_login_active;
        },

        product_settings() {
            return this.$store.getters.product_settings;
        },

        product_data() {
            return this.$store.getters.product_data;
        },

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  Submit login
         */
        submit_login() {

            if( 
                this.product_data.product_settings.widget.legal && 
                this.product_data.product_settings.widget.legal.enabled == true && 
                this.product_data.product_settings.widget.legal.consent && 
                !this.auth_data.consent 
            ) {
                // set error
                this.$store.dispatch( 'notification_add', { name: 'auth_consent_error', type: 'error', message: 'Please agree to the Terms and Conditions and Privacy Policy before proceeding.' } );
                // stop execution
                return false;
            }

            // start loading
            this.loading = true;

            // login user
            this.$store.dispatch( 'widget_user_auth', this.auth_data )
            .then((data) => {
                // stop loading
                this.loading = false;
                // check if we need to confirm
                if( data.confirm == true ) { 
                    // set confirm form
                    this.verify_active = true;
                    // setart resend counter
                    this.resend_start_counter();
                    // wait for animation to complete
                    setTimeout(function(){
                        // load auto load
                        this.key_input();
                    }.bind(this), 500);
                }
            })
            .catch(e => {
                // stop loading
                this.loading = false;
                // set error
                this.$store.dispatch( 'notification_add', { name: 'comment_error', type: 'error', message: e.message } );
            });

        },

        /**
         *  Submit confirm
         */
        submit_confirm() {

            // start loading
            this.loading = true;

            // login user
            this.$store.dispatch( 'widget_user_auth_confirm', this.auth_data )
            .then((data) => {
                // stop loading
                this.loading = false;
                // check if we need to confirm
                if( data.confirm == true ) { 
                    // set confirm form
                    this.verify_active = true; 
                }
            })
            .catch(e => {
                // stop loading
                this.loading = false;
                // reset code
                this.auth_data.confirm_1 = '';
                this.auth_data.confirm_2 = '';
                this.auth_data.confirm_3 = '';
                this.auth_data.confirm_4 = '';
                // set error
                this.$store.dispatch( 'notification_add', { name: 'comment_error', type: 'error', message: e.message } );
            });

        },

        /**
         *  Cancel login
         */
        cancel() {

            // cancel user login
            this.$store.dispatch( 'widget_user_cancel' );

        },

        /**
         *  Cancel login
         */
        skip() {

            // set auth data
            this.auth_data = {anonymous: true};

            // do anonymous user login
            this.submit_login();

        },

        /**
         *  Key input
         */
        key_input( e ) {

            var target = e.srcElement || e.target;
            var maxLength = parseInt(target.attributes["maxlength"].value, 10);
            var myLength = target.value.length;
            if (myLength >= maxLength) {
                var next = target;
                while (next = next.nextElementSibling) {
                    if (next == null)
                        break;
                    if (next.tagName.toLowerCase() === "input") {
                        next.focus();
                        break;
                    }
                }
            }
            // Move to previous field if empty (user pressed backspace)
            else if (myLength === 0) {
                var previous = target;
                while (previous = previous.previousElementSibling) {
                    if (previous == null)
                        break;
                    if (previous.tagName.toLowerCase() === "input") {
                        previous.focus();
                        break;
                    }
                }
            }

            // auto submit
            this.key_input_autosubmit();

        },

        /**
         *  Key input auto submit
         */
        key_input_autosubmit( e ) {

            // check if all keys set
            if( this.auth_data.confirm_1 && this.auth_data.confirm_2 && this.auth_data.confirm_3 && this.auth_data.confirm_4 ) {
                // submit confirm
                this.submit_confirm();
            }

        },

        /**
         *  Do resend
         */
        resend_email() {

            // check for active interval
            if( this.resend_active !== true ) {
                // init was unsuccessfull
                return false;
            }

            // submit login again
            this.submit_login();

        },

        /**
         *  Start resend counter
         */
        resend_start_counter() {

            // check for active interval
            if( this.resend_interval !== false ) {
                // init was unsuccessfull
                return false;
            }

            // set active to false
            this.resend_active = false;

            // start resend countdown
            this.resend_interval = window.setInterval(() => {

                // if counter is on zero
                if( this.resend_counter === 0 ) {
                    // stop interval
                    clearInterval(this.resend_interval);
                    // set interval to false
                    this.resend_interval = false;
                    // set resend to active
                    this.resend_active = true;
                    // reset resend counter
                    this.resend_counter = 120;
                    // done
                    return true;
                }
                // count down
                this.resend_counter = this.resend_counter - 1;

            }, 1000);

        }

    },

}
</script>
