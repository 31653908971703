/**
 * **************************
 * General:
 * Widget Settings (Dynamic widget settings to access from outside via window.SLEEK_SETIINGS)
 * **************************
 */

const SETTINGS = {

    // get settings
    get( setting = false ) {

        // if no setting key provided return false
        if(setting === false) return false;

		// default settings
        let settings_default = {

			/**
			 * Widget type 
			 * (string) embedded, standalone
			 *  */ 
			type: ((window.self !== window.top) ? 'embedded' : 'standalone'),

			/**
			 * Widget sub type 
			 * (string) widget, iframe, website
			 *  */ 
			sub_type: ((window.self !== window.top) ? ((this.check_sdk_access() === true) ? 'widget' : 'iframe') : 'website'),

			/**
			 * Cookie domain
			 * (string) *
			 *  */
			cookie_domain: ((location.hostname !== 'localhost' && location.hostname !== "") ? document.domain.match(/[^\.]*\.[^.]*$/)[0] : location.hostname),

		};

		// set external settings variable
		let settings_external = {};

		// try to load
		try {
			// load external settings
			settings_external = (
				( window && window.SLEEK_SETTINGS !== undefined ) 
				? window.SLEEK_SETTINGS : 
				(
					( window && window.parent && window.parent.SLEEK_SETTINGS !== undefined && typeof window.parent.SLEEK_SETTINGS === "object" ) 
					? window.parent.SLEEK_SETTINGS : {} 
				) 
			);
		} catch(e) {}
		
		// combine and override default data with external if exist
		let setting_combined = Object.assign( {}, settings_default, settings_external );

		// check for existing settings and return
		if( setting_combined[setting] === undefined ) {

			// return false
			return false;

		// if we have a value
		} else {

			// return settings value
			return setting_combined[setting];
			
		}

    },

	// check sdk access
	check_sdk_access() {
        // try if parent document is reachable
        try {
			return window.parent.$sleek ? true : false;
		} catch (error) {
			return false; // Handle the error condition
		}
    }
	
};

export default SETTINGS;