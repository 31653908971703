/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    widget_data_header: false,
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set widget header
    WIDGET_DATA_HEADER_SET( state, data ) { state.widget_data_header = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Set widget header
	 * @param {*} query
	 */
    widget_data_header_set( context, {title, image, description} ) {

        // set feedback query
        context.commit('WIDGET_DATA_HEADER_SET', {
            title: ((title) ? title : false), 
            description: ((description) ? description : false), 
            image: ((image) ? image : false)
        }); 
        
    },

    /**
	 * Reset widget header
	 * @param {*} query
	 */
    widget_data_header_reset( context ) {

        // set feedback query
        context.commit('WIDGET_DATA_HEADER_SET', false); 
        
    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get widget_data_header
	widget_data_header(state) {
		return state.widget_data_header;
    },
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};