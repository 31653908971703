import Vue from "vue";
import Vuex from "vuex";

import notification from "./module.notification";
import user from "./module.user";
import product from "./module.product";
import feedback_item from "./module.feedback_item";
import feedback_query from "./module.feedback_query";
import feedback_vote from "./module.feedback_vote";
import feedback_priority from "./module.feedback_priority";
import feedback_comment from "./module.feedback_comment";
import feedback_subscription from "./module.feedback_subscription";
import feedback_user from "./module.feedback_user";
import changelog from "./module.changelog";
import product_actions from "./module.product_actions";
import product_templates from "./module.product_templates";
import product_tags from "./module.product_tags";
import widget_user from "./module-w.user";
import widget_satisfaction from "./module-w.satisfaction";
import widget_promoter from "./module-w.promoter";
import widget_survey from "./module-w.survey";
import widget_notifcations from "./module-w.notifications";
import widget_subscription from "./module-w.changelog_subscription.js";
import widget_sync from "./module-w.sync";
import widget_data from "./module-w.data";
import wapi from "./module.wapi";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		notification,
		user,
        product,
        feedback_item,
        feedback_query,
        feedback_vote,
        feedback_priority,
        feedback_comment,
        changelog,
        feedback_subscription,
        feedback_user,
        product_actions,
        product_templates,
        product_tags,
        widget_user,
        widget_satisfaction,
        widget_promoter,
        widget_survey,
        widget_notifcations,
        widget_subscription,
        widget_sync,
        widget_data,
        wapi
	}
});
