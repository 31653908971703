<template>
    <div class="page home">

        <!-- Header -->
        <div class="top">
            <div class="top-inner">

                <div class="head">
                    <div>
                        <img v-if="product_data.product_img.includes('product.png') === false" :src="product_data.product_img">
                        <h1>{{variables.render(product_data.product_settings.widget.text.title)}}</h1>
                    </div>
                    <h2>{{variables.render(product_data.product_settings.widget.text.description)}}</h2>
                </div>

                <!-- If Satsifaction -->
                <div class="satisfaction" v-if="product_data.product_settings.general.module_satisfaction == true && product_data.product_settings.widget.enable_satisfaction == true">
                    <h2>{{variables.render(product_data.product_settings.widget.text.satisfaction_title)}}</h2>
                    <div class="inner">
                        <div class="col">
                            <div class="satisfaction-out">
                                <img v-if="header_style == 'light'" :class="{active: satisfaction.vote == 1}" class="satisfaction-vote" v-on:click="satisfaction_vote(1)" src="@/assets/vote/1.svg">
                                <img v-else :class="{active: satisfaction.vote == 1}" class="satisfaction-vote" v-on:click="satisfaction_vote(1)" src="@/assets/vote/__1.svg">
                            </div>
                        </div>
                        <div class="col">
                            <div class="satisfaction-out">
                                <img v-if="header_style == 'light'" :class="{active: satisfaction.vote == 2}" class="satisfaction-vote" v-on:click="satisfaction_vote(2)" src="@/assets/vote/2.svg">
                                <img v-else :class="{active: satisfaction.vote == 2}" class="satisfaction-vote" v-on:click="satisfaction_vote(2)" src="@/assets/vote/__2.svg">
                            </div>
                        </div>
                        <div class="col">
                            <div class="satisfaction-out">
                                <img v-if="header_style == 'light'" :class="{active: satisfaction.vote == 3}" class="satisfaction-vote" v-on:click="satisfaction_vote(3)" src="@/assets/vote/3.svg">
                                <img v-else :class="{active: satisfaction.vote == 3}" class="satisfaction-vote" v-on:click="satisfaction_vote(3)" src="@/assets/vote/__3.svg">
                            </div>
                        </div>
                        <div class="col">
                            <div class="satisfaction-out">
                                <img v-if="header_style == 'light'" :class="{active: satisfaction.vote == 4}" class="satisfaction-vote" v-on:click="satisfaction_vote(4)" src="@/assets/vote/4.svg">
                                <img v-else :class="{active: satisfaction.vote == 4}" class="satisfaction-vote" v-on:click="satisfaction_vote(4)" src="@/assets/vote/__4.svg">
                            </div>
                        </div>
                        <div class="col">
                            <div class="satisfaction-out">
                                <img v-if="header_style == 'light'" :class="{active: satisfaction.vote == 5}" class="satisfaction-vote" v-on:click="satisfaction_vote(5)" src="@/assets/vote/5.svg">
                                <img v-else :class="{active: satisfaction.vote == 5}" class="satisfaction-vote" v-on:click="satisfaction_vote(5)" src="@/assets/vote/__5.svg">
                            </div>
                        </div>
                        <div class="scala"></div>
                    </div>
                </div>

                <!-- If Subscribe -->
                <div class="subscribe" v-else-if="product_data.product_settings.general.module_feedback !== true && product_data.product_settings.general.subscribe_changelog == true" v-on:click="changelog_subscription()">
                    {{subscription}}
                </div>

                <!-- If None -->
                <div v-else class="empty"></div>

            </div>
        </div>

        <!-- Add Feedback Button -->
        <div class="main-button" v-if="main_module == 'feedback'">
            <router-link :to="{ name: 'feedback-add' }" v-if="product_data.product_settings.widget.enable_submit" class="button-inner">
                <div class="button-icon-out">
                    <div class="button-icon"> 
                        <ion-icon name="add"></ion-icon> 
                    </div>
                </div>
                <div class="button-text">
                    <div v-html="((product_data.product_settings.widget.text.post_button && product_data.product_settings.widget.text.post_button.length > 0) ? product_data.product_settings.widget.text.post_button : $t('feedback_button') )"></div>
                </div>
            </router-link>
        </div>

        <!-- Main Content -->
        <div class="main-content">
            <!-- Feedback Board -->
            <feedback        v-if="main_module === 'feedback'" />
            <!-- Changelog -->
            <changelog  v-else-if="main_module === 'changelog'" />
            <!-- Roadmap -->
            <roadmap    v-else-if="main_module === 'roadmap'" />
            <!-- Actions -->
            <actions    v-else-if="main_module === 'actions'" />
        </div>

    </div>
</template>

<script>
import Settings from '@/common/general-settings.js';
import VariableService from "@/common/general-variables";

export default {

    name: 'Home',


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        
        // data
		return {
            // general
            parallax_timeout: false,
            parallax_data: {},
            subscription: 'Subscribe',
            // variables
            variables: VariableService.init(this),
        };

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        product_data() {
            return this.$store.getters.product_data;
        },

        satisfaction() {
            return this.$store.getters.widget_satisfaction;
        },

        main_module() {

            // init empty main module
            var main_module = '';

            // load active module
            if( this.product_data.product_settings.widget.home === 'feedback' && this.product_data.product_settings.general.module_feedback == true ) {
                main_module = 'feedback';
            } else if( this.product_data.product_settings.widget.home === 'feedback' && this.product_data.product_settings.general.module_feedback !== true ) {
                main_module = 'changelog';
            } else if( this.product_data.product_settings.widget.home === 'changelog' && this.product_data.product_settings.general.module_changelog == true ) {
                main_module = 'changelog';
            } else if( this.product_data.product_settings.widget.home === 'changelog' && this.product_data.product_settings.general.module_changelog !== true ) {
                main_module = 'feedback';
            } else if( this.product_data.product_settings.widget.home === 'roadmap' ) {
                main_module = 'roadmap';
            } else if( this.product_data.product_settings.widget.home === 'actions' && Settings.get('type') !== 'standalone' ) {
                main_module = 'actions';
            } else if( this.product_data.product_settings.widget.home === 'actions' && Settings.get('type') == 'standalone' ) {
                main_module = this.product_data.product_settings.widget.home_fallback;
            }

            // return module
            return main_module;

        },

        header_style() {

            // return header style 'light' || 'dark'
            return ((
                !this.product_data.product_settings.widget.brand_color_txt ||
                 this.product_data.product_settings.widget.brand_color_txt !== '#000000'
                ) ? 'light' : 'dark' );
                
        }
        
    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  Load satisfaction
         */
		satisfaction_load() {

            // load satisfaction
            this.$store.dispatch( 'widget_satisfaction_get' )
            .then((data) => {})
            .catch(e => {});
			
        },

        /**
         *  Vote satisfaction
         */
		satisfaction_vote( vote ) {

            // vote data
            let vote_data = {vote: vote, data: 'default'};

            // try to get parent window
            try {
                // try to gte parent location if in widget
                if( window.parent !== window.self && window.parent.location.href ) {
                    // set parent href as referrer
                    vote_data.meta = { referrer: window.parent.location.href };
                }
            } catch(e) {}

            // check for user
            this.$store.dispatch( 'widget_user_check' ).then((data) => {

                // set satisfaction vote
                this.$store.dispatch( 'widget_satisfaction_set', vote_data ).then((data) => {

                    // load comment form
                    this.$router.push({ path: '/satisfaction', query: { 
                        vote:   vote_data.vote, 
                        title:  this.product_data.product_settings.widget.text.satisfaction_title, 
                        text:   this.product_data.product_settings.widget.text.satisfaction_voted,
                        data:   vote_data.data
                    } });

                });

            }).catch(e => {});
			
        },

        /**
         *  Load changelog subscription
         */
        load_changelog_subscription() {

            // check if feedback module is enabled
            if( this.product_data.product_settings.general.module_feedback == true ) {
                // dont load changelog data
                return false;
            }

            // check if user is subscribed
            if( this.$store.getters.widget_changelog_subscription === true ) {

                // update button
                this.subscription = 'Unsubscribe';

                // done
                return true;

            }

            // load changelog subscription state
			this.$store.dispatch( 'widget_changelog_subscription' ).then((data) => {
                
                // check if user is subscribed
                if( this.$store.getters.widget_changelog_subscription === true ) {

                    // update button
                    this.subscription = 'Unsubscribe';

                }

            });

        },

        /**
         *  Do changelog subscription
         */
        changelog_subscription() {

            // check for user
            this.$store.dispatch( 'widget_user_check' ).then((data) => {

                // load changelog subscription state
			    this.$store.dispatch( 'widget_changelog_subscription' ).then((data) => {

                    // check subscription state
                    if( this.$store.getters.widget_changelog_subscription === true ) {

                        // unsubscribe
                        this.$store.dispatch( 'widget_changelog_unsubscribe' ).then((data) => {});
                        
                        // update button
                        this.subscription = 'Subscribe';

                    } else {

                        // subscribe
                        this.$store.dispatch( 'widget_changelog_subscribe' ).then((data) => {});
                        
                        // update button
                        this.subscription = 'Unsubscribe';

                    }

                });

            });

        },

        /**
         *  Parallax scrolling
         */
        parallax() {

            // get elements for parallax
            this.parallax_data.header        = document.querySelector('.top');
            this.parallax_data.header_inner  = document.querySelector('.top-inner');
            this.parallax_data.main          = document.querySelector('.main-content');

            // set parallax stop
            this.parallax_data.top_limit     = document.querySelector('.header').offsetHeight;
            // set top distance to 0
            this.parallax_data.top           = 0;

            // get top offset for main
            this.parallax_data.top_offset    = this.parallax_data.main.getBoundingClientRect().top;

            // (re)set default
            document.querySelector('.header').classList.add('transparent');
            document.querySelector('.main-content').classList.remove('fixed');

            // initial
            this.parallax_scroll();

            // on load scroll
            document.addEventListener('scroll', this.parallax_scroll);

        },

        /**
         *  Parallax on scrolling
         */
        parallax_scroll() {
                
            // set y-offset
            const yoff = window.pageYOffset;

            // set mount scale
            const MOUNT_SCALE_END = this.parallax_data.top_offset;

            // check y offset < moun scale
            if (yoff < MOUNT_SCALE_END) {
                // set percentage
                const perc = yoff / MOUNT_SCALE_END; // how far through the animation are we?
                // load animation
                this.parallax_data.header_inner.style.transform = `translateY(${1 + 150 * perc}px)`;
                this.parallax_data.header_inner.style.opacity = `${1 - 1.8 * perc}`; 
                this.parallax_data.header.style.setProperty('--background-opacity', `${1 - 1.8 * perc}`); 
            }

            // get top offset for main
            this.parallax_data.top = this.parallax_data.main.getBoundingClientRect().top;

            // if top offset is more than 80px
            if( this.parallax_data.top <= this.parallax_data.top_limit ) {
                // load fixed menu bar
                document.querySelector('.header').classList.remove('transparent');
                document.querySelector('.main-content').classList.add('fixed');
            } else {
                // default menu bar
                document.querySelector('.header').classList.add('transparent');
                document.querySelector('.main-content').classList.remove('fixed');
            }

        },

        /**
         *  Stop parallax scrolling
         */
        parallax_stop() {

            // check if prallax still not started
            if(this.parallax_timeout !== false) {
                clearTimeout(this.parallax_timeout);
            }

            // remove parallax
            document.querySelector('.header').classList.remove('transparent');

            // stop event listener
            document.removeEventListener('scroll', this.parallax_scroll);

        },

	},


    /** ----------------------------------------------------
     * View => Registred components
     ----------------------------------------------------- */
    components: {
        'feedback': () => import('./feedback.vue'),
        'changelog': () => import('./changelog.vue'),
        'roadmap': () => import('./roadmap.vue'),
        'actions': () => import('./actions.vue')
    },


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted() {

        if( ! this.satisfaction  ) {
            // load satisfaction
            this.satisfaction_load();
        }

        // timeout to give the page some time to render
        this.parallax_timeout = setTimeout(() => {
            // parallax effect
            this.parallax();
            // set header
            this.$store.dispatch( 'widget_data_header_set', {
                title: ((this.product_data.product_settings.widget.text.title) ? this.variables.render(this.product_data.product_settings.widget.text.title) : false),
                description: ((this.product_data.product_settings.widget.text.description) ? this.variables.render(this.product_data.product_settings.widget.text.description) : false),
                image: ((this.product_data.product_img.includes('product.png') === false) ? this.product_data.product_img : false)
            } );
        }, 500);

        // load subscription state 
        // in case we have changelog on start screen
        this.load_changelog_subscription();

    },


    /** ----------------------------------------------------
     * View => On Destroyed
     ----------------------------------------------------- */
    destroyed() {

        // parallax effect stop
        this.parallax_stop();

        // reset header
        this.$store.dispatch( 'widget_data_header_reset' );

    },


    /** ----------------------------------------------------
     * Header
     ----------------------------------------------------- */
    head: {
        // Meta title
        title: function () {
            return {
                inner: this.product_data.product_description,
                separator: '|',
                complement: this.product_data.product_name
            };
        },
        // Meta tags
        meta: [],
        // Link tags
        link: [
            { 
                rel: 'canonical', 
                href: 'https://' + (location.host).replace('www', '') + location.pathname, 
                id: 'canonical' 
            },
        ]
    }


}
</script> 