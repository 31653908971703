import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";
import WidgetSync from "@/common/widget-sync";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    widget_login_active: false,
    widget_login_token: { cancel: () => {}, success: () => {} },
    widget_user_is_loggedin: ((JwtService.getData().user_data) ? true : false),
    widget_user_data: ((JwtService.getData().user_data) ? JwtService.getData().user_data : false),
    widget_user_token: ((JwtService.getData().token) ? JwtService.getData().token : false),
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set button
    WIDGET_SET_USER_LOGIN( state, data ) { state.widget_login_active = data; },

    // Set login status
    WIDGET_SET_USER_LOGIN_STATUS( state, data ) { state.widget_user_is_loggedin = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Set button
	 * @param {*} query
	 */
    widget_user_check( context ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 

            // check if user is loggedin
            if( state.widget_user_is_loggedin == false ) {

                // trigger auth
                WidgetSync.trigger('user_auth_required');

                // call from outside if login is aborded
                state.widget_login_token.cancel = function() {
                    // set rejection
                    return reject({});
                };

                // call from outside if login is successfull
                state.widget_login_token.success = function() {
                    // set success
                    return resolve({});
                };
                
                // trigger single sign-on
                WidgetSync.auth( function( sso_data ) {

                    // load auth data merged with synced user data
                    var auth_data = Object.assign( {}, context.getters.widget_sync.user, sso_data );

                    // first check if we have no user mail or SSO token
                    if( 
                        auth_data.anonymous === undefined && 
                        auth_data.data_mail === undefined && 
                        auth_data.data_token === undefined 
                    ) {
                        // show user login form
                        context.commit('WIDGET_SET_USER_LOGIN', true); 
                    } else {
                        // authenticate user directly
                        context.dispatch( 'widget_user_auth', auth_data );
                        // remove data
                        context.dispatch( 'widget_remove_data', 'user' );
                    }

                } );

            // if user is already loggedin
            } else {
                // set success
                return resolve({});
            }

        });
        
    },

    /**
	 * Set button
	 * @param {*} query
	 */
    widget_user_cancel( context ) {

        // cancel
        state.widget_login_token.cancel();

        // unset user login form
        context.commit('WIDGET_SET_USER_LOGIN', false); 
        
    },

    /**
	 * Set button
	 * @param {*} query
	 */
    widget_user_auth( context, user_data ) {

        // call api to auth user
		return new Promise( function(resolve, reject) { 

            // trigger auth
            WidgetSync.trigger('user_auth_start');

            // send data
            ApiService.post('feedback/' + context.getters.product_active_id + '/auth', user_data, function( data ) {

                // check if we have confirm
                if( data.confirm ) {

                    // trigger auth
                    WidgetSync.trigger('user_auth_confirm');

                    // set success
                    return resolve({confirm: true});

                } else {

                    // trigger auth
                    WidgetSync.trigger('user_auth_completed');

                    // success
                    state.widget_login_token.success();
                    // widget user set
                    context.dispatch( 'widget_user_set', data );
                    // set user login status
                    context.commit('WIDGET_SET_USER_LOGIN_STATUS', true); 
                    // unset user login form
                    context.commit('WIDGET_SET_USER_LOGIN', false); 
                    // set success
                    return resolve({confirm: false});

                }

            }, function( data ) {

                // trigger auth
                WidgetSync.trigger('user_auth_error');

                // set rejection
                return reject(data);

            });
            
        });
        
    },

    /**
	 * Set button
	 * @param {*} query
	 */
    widget_user_auth_confirm( context, confirm_data ) {

        // call api to auth user
		return new Promise( function(resolve, reject) { 

            // send data
            ApiService.post('feedback/' + context.getters.product_active_id + '/auth/confirm', confirm_data, function( data ) {

                // trigger auth
                WidgetSync.trigger('user_auth_completed');

                // success
                state.widget_login_token.success();
                // widget user set
                context.dispatch( 'widget_user_set', data );
                // set user login status
                context.commit('WIDGET_SET_USER_LOGIN_STATUS', true); 
                // unset user login form
                context.commit('WIDGET_SET_USER_LOGIN', false);
                // set success
                return resolve({confirm: false});

            }, function( data ) {

                // trigger auth
                WidgetSync.trigger('user_auth_error');

                // cancel
                state.widget_login_token.cancel();
                // set rejection
                return reject(data);

            });
            
        });
        
    },

    /**
	 * Edit User
	 * @param {*} context 
	 * @param {object} user_edit 
	 */
	widget_user_edit( context, user_edit ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.update('feedback/' + context.getters.product_active_id + '/user', context.getters.user_data.user_id, user_edit, function( data ) {

				// widget user set
                context.dispatch( 'widget_user_set', {user_data: data} );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},

	/**
	 * Remove User
	 * @param {*} context 
	 */
	widget_user_remove( context ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('feedback/' + context.getters.product_active_id + '/user/' + context.getters.user_data.user_id, function( data ) {

                // widget user logout
                context.dispatch( 'widget_user_logout' );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },
    
    /**
	 * Upload a user image
	 * @param {*} context 
	 * @param {object} name file 
	 */
	widget_user_image( context, {name, file} ) {

        // call api to get users
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.upload('feedback/' + context.getters.product_active_id + '/user/' + context.getters.user_data.user_id + '/image', name, file, function( data ) {

                // widget user set
                context.dispatch( 'widget_user_set', {user_data: data} );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

    /**
	 * Delete a user image
	 * @param {*} context 
	 */
	widget_user_image_remove( context ) {

        // call api to get users
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('feedback/' + context.getters.product_active_id + '/user/' + context.getters.user_data.user_id + '/image', function( data ) {

                // widget user set
                context.dispatch( 'widget_user_set', {user_data: data} );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},

    /**
	 * Get User Segment
	 * @param {*} context 
	 */
	widget_user_segment( context ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 

            // check if we do not have a logged in user with an user_id
            if( !context.getters.user_data || !context.getters.user_data.user_id ) {
                // set rejection
                return reject({segments: []});
            }

			// load data via API
			ApiService.get('feedback/' + context.getters.product_active_id + '/user/' + context.getters.user_data.user_id, 'segment', function( data ) {

                // merge user data with segments
                var new_user_data = Object.assign( context.getters.user_data, {segments: data.segments} );
                // widget user set data
                context.dispatch( 'widget_user_set', {user_data: new_user_data} );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Set user data
	 * @param {*} query
	 */
    widget_user_set( context, data ) {

        // update object
        var update = {};

        // set update data
        if( data.user_data ) { update.user_data = data.user_data; }
        if( data.token ) { update.token = data.token; }

        // keeps sso state if we have one
        if( context.getters.user_data && context.getters.user_data.sso == true ) {
            // set sso state
            update.user_data.sso = true;
        }

        // set user data
        context.commit('USER_SET_DATA', update); 
        // save data to localstorage
        JwtService.modData( update );
        // reinit API key
        ApiService.init();
        // trigger auth refresh
        WidgetSync.trigger('user_auth_set', update);
    
    },

    /**
	 * Logout
	 * @param {*} context 
	 */
	widget_user_logout( context ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 

			// remove user
            JwtService.destroyData();
            JwtService.destroyData('product');

			// set data
			context.commit('USER_SET_DATA', {}); 
            context.commit('WIDGET_SET_USER_LOGIN', false); 
            
            // set user login status
            context.commit('WIDGET_SET_USER_LOGIN_STATUS', false); 

			// set success
			return resolve();

		});

	},

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get feedback changelogs
	widget_login_active(state) {
		return state.widget_login_active;
    },
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};