import Settings from "@/common/general-settings";

/**
 * **************************
 * General:
 * store data
 * **************************
 */

const VariableService = {
    
    _this: {},

    init( _this ) {

        this._this = _this;

        return this;

    },

    render( string, _this ) {

        var render_variables = {
            user_name:          ((this._this.$store.getters.user_data && this._this.$store.getters.user_data.data_name) ? this._this.$store.getters.user_data.data_name : false),
            user_full_name:     ((this._this.$store.getters.user_data && this._this.$store.getters.user_data.data_full_name) ? this._this.$store.getters.user_data.data_full_name : false),
            user_first_name:    ((this._this.$store.getters.user_data && this._this.$store.getters.user_data.data_full_name) ? this._this.$store.getters.user_data.data_full_name.split(' ')[0] : false ),
            user_email:         ((this._this.$store.getters.user_data && this._this.$store.getters.user_data.data_mail) ? this._this.$store.getters.user_data.data_mail : false)
        };

        return string.replace(/{([^{}|]+)(?:\|([^{}]+))?}/g, (match, key, fallback) => {
            return render_variables[key.trim()] || fallback || match;
        });

    },

};

export default VariableService;