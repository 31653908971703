import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    feedback_comments: false,
    feedback_comments_query: {},
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set active product
    FEEDBACK_SET_COMMENTS( state, data ) { state.feedback_comments = data; },

    // Set feedback query
    FEEDBACK_SET_COMMENTS_QUERY( state, data ) { state.feedback_comments_query = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Query comments
	 * @param {*} query
	 */
    feedback_comment_query( context, {feedback_id, query} ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 
            
            // load data via API
            ApiService.query('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/comments', query, function( data ) {

                // load new product into our product store
                context.dispatch( 'feedback_comment_add', {items: data} );

                // load new product into our product store
                context.dispatch( 'feedback_comment_add_query', {[data.feedback_id]: {has_more: data.has_more, page: data.page}} );

                // set success
                return resolve(data);

            }, function( data ) {

                // load new product into our product store
                context.dispatch( 'feedback_comment_add', {items: data} );

                // load new product into our product store
                context.dispatch( 'feedback_comment_add_query', {[data.feedback_id]: {has_more: data.has_more, page: data.page}} );

                // set rejection
                return reject(data);

            });

        });
        
    },

	/**
	 * Load / refresh comment
	 * @param {*} {feedback_id, force}
	 */
    feedback_comment_load( context, {feedback_id, comment_id} ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 

            // load data via API
            ApiService.get('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/comment', comment_id, function( data ) {

                // load new product into our product store
                context.dispatch( 'feedback_comment_add', {item: data} );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });
        
    },
    
    /**
	 * Create new comment
	 * @param {*} context 
	 */
    feedback_comment_create( context, {feedback_id, comment_data} ) {

        // call api to create feedback
		return new Promise( function(resolve, reject) { 

            // send data
            ApiService.post('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/comment', comment_data, function( data ) {

                // get single product
                context.dispatch( 'feedback_comment_load', {feedback_id: feedback_id, comment_id: data.comment_id} );

                // get parent product
                if(comment_data.parent) { context.dispatch( 'feedback_comment_load', {feedback_id: feedback_id, comment_id: comment_data.parent} ); }

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },
    
    /**
	 * Update comment
	 * @param {*} context 
	 */
	feedback_comment_update( context, {feedback_id, comment_id, comment_data} ) {

        // call api to update feedback
		return new Promise( function(resolve, reject) { 

            // update feedback via api
            ApiService.update('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/comment', comment_id, comment_data, function( data ) {

                // get single product
                context.dispatch( 'feedback_comment_load', {feedback_id: feedback_id, comment_id: data.comment_id} );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Delete comment
	 * @param {*} context 
	 */
	feedback_comment_delete( context, {feedback_id, comment_id} ) {

        // call api to update feedback
		return new Promise( function(resolve, reject) { 

            // update feedback via api
            ApiService.delete('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/comment/' + comment_id, function( data ) {

                // get single product
                if( data.status == 'anonymized' ) {
                    context.dispatch( 'feedback_comment_load', {feedback_id: feedback_id, comment_id: data.comment_id} );
                }

                // remove
                if( data.status == 'deleted' ) {
                    context.dispatch( 'feedback_comment_remove', {feedback_id: feedback_id, comment_id: data.comment_id} );
                }
                
                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Like comment
	 * @param {*} context 
	 */
	feedback_comment_like( context, {feedback_id, comment_id} ) {

        // call api to update feedback
		return new Promise( function(resolve, reject) { 

            // update feedback via api
            ApiService.post('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/comment/' + comment_id + '/like/', {}, function( data ) {

                // update comment item
                context.dispatch( 'feedback_comment_load', {feedback_id: feedback_id, comment_id: comment_id} );
                
                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Add comment(s) to store
	 * @param {*} context 
	 */
	feedback_comment_add( context, {items, item} ) {

        // load current feedback items
        var feedback_comments = Object.assign({}, context.getters.feedback_comments);

        // for items object
        if( items ) {
            // add new item(s)
            if(feedback_comments[items.feedback_id]) Object.assign(feedback_comments[items.feedback_id], items.comments);
            else feedback_comments[items.feedback_id] = items.comments;
        }

        // for single item
        if( item ) {
            // if object not exist
            if( !feedback_comments[item.feedback_id] ) {
                // create object first
                feedback_comments[item.feedback_id] = {};
            }
            // add new item(s)
            feedback_comments[item.feedback_id][item.comment_id] = item;
        }

        // set feedback items
        context.commit('FEEDBACK_SET_COMMENTS', feedback_comments); 

    },

    /**
	 * Remove comment(s) from store
	 * @param {*} context 
	 */
	feedback_comment_remove( context, {feedback_id, comment_id} ) {

        // load current feedback items
        var feedback_comments = Object.assign({}, context.getters.feedback_comments);

        // for single item
        if( feedback_id && comment_id ) {
            // add new item(s)
            delete feedback_comments[feedback_id][comment_id];
        }

        // set feedback items
        context.commit('FEEDBACK_SET_COMMENTS', feedback_comments); 

    },

    /**
	 * Add query to store
	 * @param {*} context 
	 */
	feedback_comment_add_query( context, query ) {

        // load current feedback query
        var feedback_comments_query = Object.assign({}, context.getters.feedback_comments_query);

        // add new item(s)
        Object.assign(feedback_comments_query, query);

        // set feedback query
        context.commit('FEEDBACK_SET_COMMENTS_QUERY', feedback_comments_query); 

    },

    /**
	 * Remove query from store
	 * @param {*} context 
	 */
	feedback_comment_clear( context ) {

        // set feedback query
        context.commit('FEEDBACK_SET_COMMENTS_QUERY', {}); 

        // set feedback items
        context.commit('FEEDBACK_SET_COMMENTS', false); 

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get feedback comments
	feedback_comments(state) {
		return state.feedback_comments;
    },
    
    // get feedback comments
	feedback_comments_query(state) {
		return state.feedback_comments_query;
	},
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};