<template>
    <div>
        <div v-for="(item, index) in notifications" class="notification">
            <div class="notification-icon">
                <ion-icon v-if="item.type == 'success'" name="ios-checkmark"></ion-icon>
                <ion-icon v-if="item.type == 'error'" name="ios-warning"></ion-icon>
            </div>
            <div class="notification-text" :class="item.type">
                <div>{{item.message}}</div>
            </div>
            <div class="notification-timer"></div>
        </div>
    </div>
</template>

<script>
export default {

    name: 'notification',


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        notifications() {
            return this.$store.getters.notifications;
        }

    }

}
</script>