/**
 * **************************
 * General:
 * Token management for local data
 * **************************
 */

// identifier name for local storage
const ID_TOKEN_KEY = "_sleek_product";

// import Store service
import StoreService from "@/common/general-store";

// set Storage service
StoreService.init(ID_TOKEN_KEY, 'ck');

// export
export default {

	// get localstorage
	getData( store = false ) {
		let local_store = ((store == false) ? ID_TOKEN_KEY : ID_TOKEN_KEY + '_' + store );
		return StoreService.get( local_store );
	},

	// set localstorage
	setData( data, store = false ) {
		let local_store = ((store == false) ? ID_TOKEN_KEY : ID_TOKEN_KEY + '_' + store );
        StoreService.set( local_store, data );
    },
    
    // set localstorage
	modData( data, store = false ) {
		let local_store = ((store == false) ? ID_TOKEN_KEY : ID_TOKEN_KEY + '_' + store );
        let mod_data = Object.assign({}, this.getData(store), data);
		StoreService.set( local_store, mod_data );
	},

	// destroy localstorage
	destroyData( store = false ) {
		let local_store = ((store == false) ? ID_TOKEN_KEY : ID_TOKEN_KEY + '_' + store );
		StoreService.remove( local_store );
    },

    // check localstorage
	existData( check, store = false ) {
        let data = this.getData(store);
        if( data.hasOwnProperty(check) ) { return true; }
        return false;
	},
    
    // get localstorage
	getUserData() {
		return this.getData().user_data;
    },
    
    // get localstorage
	getProductData() {
		return this.getData().product_data;
	},

};