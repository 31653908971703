import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

	/**
	 * API Wrapper => GET
	 * @param {*} context 
	 * @param {object} {resource, params} 
	 */
	wapi_query( context, {resource, params} ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 
			
			// load data via API
			ApiService.query(resource, slug, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },
	
	/**
	 * API Wrapper => GET
	 * @param {*} context 
	 * @param {object} {resource, slug} 
	 */
	wapi_get( context, {resource, slug} ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 
			
			// load data via API
			ApiService.get(resource, slug, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * API Wrapper => POST
	 * @param {*} context 
	 * @param {object} {resource, params} 
	 */
	wapi_post( context, {resource, params} ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 
			
			// load data via API
			ApiService.post(resource, params, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * API Wrapper => PUT
	 * @param {*} context 
	 * @param {object} {resource, params} 
	 */
	wapi_put( context, {resource, params} ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 
			
			// load data via API
			ApiService.put(resource, params, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * API Wrapper => DELETE
	 * @param {*} context 
	 * @param {object} {resource} 
	 */
	wapi_delete( context, {resource} ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 
			
			// load data via API
			ApiService.delete(resource, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

};


/***********************
 * Export
***********************/

export default {
	actions,
};