import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    product_data: false,
    product_settings: {},
	product_user: false,
    product_list: [],
    product_role: false,
    product_async: [],
	product_capabilities: {}

};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set active product
    PRODUCT_SET_ACTIVE( state, data ) { state.product_data = data; },
    
    // Set product data
    PRODUCT_SET_DATA( state, data ) { state.product_data = data; },

    // Set product settings
    PRODUCT_SET_SETTINGS( state, data ) { state.product_settings = data; },

	// Set list of availible products
	PRODUCT_SET_LIST( state, data ) { state.product_list = data; },
	
	// Set availible users for product
    PRODUCT_SET_USER( state, data ) { state.product_user = data; },

    // Set user role
    PRODUCT_SET_ROLE( state, role ) { state.product_role = role; },

	// Set product capabilities
    PRODUCT_SET_CAPS( state, data ) { state.product_capabilities = data; },
    
};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

	/**
	 * Load product
	 * @param {*} context 
	 * @param {object} product_id 
	 */
    product_load( context, load_id = false ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

			// get the default product for user id
			var default_product_id = (( load_id !== false ) ? load_id : JwtService.getData('product') ); //state.product_list;

			// check if we have a default product id
			if( default_product_id ) {

				// load new product list
				context.dispatch( 'product_get', default_product_id )
				.then(() => {
					// set default_product_id as default
					context.dispatch( 'product_set', {product_id: default_product_id} );
					// set success
					return resolve(default_product_id);
				})
				.catch(() => {
					// set rejection
					return reject();
				});
				
			} else {

				// load new product list
				context.dispatch( 'products_get' )
				.then(( product_data ) => {
					// set first product as default
					context.dispatch( 'product_set', {product_id: product_data[0].ID} );
					// set success
					return resolve(product_data[0].ID);
				})
				.catch(() => {
					// set rejection
					return reject();
				});
				
			}
            
        });

    },

	/**
	 * Set active product
	 * @param {*} context 
	 * @param {object} product_id 
	 */
    product_set( context, {product_id, product_data = false} ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // get active product details from product list
            var active_product = ((product_data == false) ? state.product_list.filter(x => x.ID === product_id)[0] : product_data );

            // if we have no data
            if( active_product === undefined ) {

                // get product data
                context.dispatch( 'product_get', product_id ).then(( product_data ) => {
                    // set product data
                    context.dispatch( 'product_set_data', product_data ).then(( return_product ) => {
                        // set success
                        return resolve(return_product);
                    })
                    .catch(() => {
                        // set rejection
                        return reject();
                    });
				})
				.catch(() => {
					// set rejection
					return reject();
                });
               
            // if we have data
            } else {

                // set product data
                context.dispatch( 'product_set_data', active_product ).then(( return_product ) => {
                    // set success
					return resolve(return_product);
				})
				.catch(() => {
					// set rejection
					return reject();
                });

            }
            
        });

    },
    
    /**
	 * Set active product data
	 * @param {*} context 
	 * @param {object} product_id 
	 */
    product_set_data( context, active_product ) {

        // set active product
        context.dispatch( 'product_set_local', active_product.ID );

        // set new active product
        context.commit('PRODUCT_SET_ACTIVE', active_product); 

        // set product settings
        context.commit('PRODUCT_SET_SETTINGS', active_product.product_settings);

        // set user role
        context.commit('PRODUCT_SET_ROLE', active_product.user_role); 
        
        // set success
        return resolve(active_product);

    },
	
	/**
	 * Set active product local storage
	 * @param {*} context 
	 * @param {object} product_id 
	 */
    product_set_local( context, product_id ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

			// set active product
			JwtService.setData(product_id, 'product');
            
            // set success
            return resolve(product_id);
            
        });

    },

    /**
	 * Get single product
	 * @param {*} context 
	 * @param {int} product_id 
	 */
    product_get( context, product_id ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

            // set default product id if not provided
            product_id = ((product_id) ? product_id : state.product_data.ID );

			// load data via API
			ApiService.get('product', product_id + '/?settings=true&role=true&admin=true&plan=true', function( data ) {

                // set product data
                context.commit('PRODUCT_SET_DATA', data); 

                // set product settings
                context.commit('PRODUCT_SET_SETTINGS', data.product_settings);
                
                // set user role
                context.commit('PRODUCT_SET_ROLE', data.user_role); 

				// check if we have caps
				if( data.product_capabilities ) { context.commit('PRODUCT_SET_CAPS', data.product_capabilities); }

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },
    
    /**
	 * Get all products for user
	 * @param {*} context 
	 */
	products_get( context ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('user', JwtService.getUserData().ID + '/product?role=true&settings=true', function( data ) {

                // set availible products
				context.commit('PRODUCT_SET_LIST', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });
        
    },

    /**
	 * Create a product
	 * @param {*} context 
	 * @param {object} product_data 
	 */
	product_create( context, product_data ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

            // check product name
            if( ! product_data.product_name ) {
                // set rejection
				return reject({message: 'You have to give your product a name!'});
            }

            // check product name
            if( ! product_data.product_description ) {
                // set rejection
				return reject({message: 'You have to add a description for your product!'});
            }

			// load data via API
			ApiService.post('product', product_data, function( data ) {

                // load new product list
                context.dispatch( 'products_get' );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

    /**
	 * Update a product
	 * @param {*} context 
	 * @param {object} product_data 
	 */
	product_update( context, product_data ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

            // add an async proc
            state.product_async.push(1);

			// load data via API
			ApiService.update('product', state.product_data.ID, product_data, function( data ) {

                // remove last async proc
                state.product_async.pop();

                // check if we still have running async processes
                if( state.product_async.length == 0 ) {
                    // update active product
                    context.dispatch( 'product_set', {product_data: data} );
                }

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

    /**
	 * Remove a product
	 * @param {*} context 
	 */
	product_remove( context ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('product/' + state.product_data.ID, function( data ) {

                // refresh availible products
                context.dispatch( 'products_get' ).then((data) => { 
					
					// IS NOT last product
					if( state.product_list && state.product_list.length >= 1 ) {

						// get latest product from list
						let latest_id = state.product_list[(state.product_list.length - 1)].ID;

						// set latest product as active
						context.dispatch( 'product_set', {product_id: latest_id} );

					// IS last product
					} else {

						// logout user
						context.dispatch( 'user_logout' ).then((data) => { 
							// load goodbye
							window.location.href = 'https://sleekplan.com/goodbye';
						});

					}

					// set success
					return resolve(data);

                });

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

    /**
	 * Upload a product image
	 * @param {*} context 
	 * @param {object} product_data 
	 */
	product_image( context, {name, file} ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.upload('/product/' + state.product_data.ID + '/image', name, file, function( data ) {

                // update active product
                context.dispatch( 'product_set', {product_data: data} );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

    /**
	 * Delete a product image
	 * @param {*} context 
	 */
	product_image_remove( context ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('/product/' + state.product_data.ID + '/image', function( data ) {

                // update active product
                context.dispatch( 'product_set', {product_data: data} );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},
	
	/**
	 * Get product user
	 * @param {*} context 
	 * @param {object} product_data 
	 */
	product_user_get( context ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('/product', state.product_data.ID + '/user', function( data ) {

				// set product user
				context.commit('PRODUCT_SET_USER', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},
	
	/**
	 * Add product user
	 * @param {*} context 
	 * @param {object} user_data 
	 */
	product_user_add( context, user_data ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('/product/' + state.product_data.ID + '/user', user_data, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},
	
	/**
	 * Delete product user
	 * @param {*} context 
	 * @param {int} user_id 
	 */
	product_user_remove( context, user_id ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('/product/' + state.product_data.ID + '/user/' + user_id, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

    // get login status
	product_active(state) {
		return state.product_data;
    },

    // get login status
	product_active_id(state) {
		return state.product_data.ID;
    },
    
    // load product settings
    product_data(state) {
		return state.product_data;
	},

	// get all products
	product_list(state) {
		return state.product_list;
	},

	// get product user
	product_user(state) {
		return state.product_user;
    },
    
    // get active product user role
	product_role(state) {
		return state.product_role;
    },
    
    // get product settings
	product_settings(state) {
		return state.product_settings;
	},

	// get plan
	product_plan_can: (state) => (caps) => {
        return ((
			state.product_capabilities[caps] !== undefined &&
			state.product_capabilities[caps] !== false
		) ? true : false);
	},

	// get feedback changelogs
	product_plan_limit: (state) => (caps) => {
        return ((
			state.product_capabilities[caps] !== undefined &&
			state.product_capabilities[caps] !== false
		) ? state.product_capabilities[caps] : 0 );
	}
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};