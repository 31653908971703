import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {

	user_is_loggedin: !!JwtService.getData(),
	user_data: JwtService.getData().user_data,
	user_token: JwtService.getData().token,
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Setup user
	USER_SET_DATA( state, data ) { state.user_data = data.user_data; },

	// Set login status
	USER_SET_LOGINSTATUS( state, status ) { state.user_is_loggedin = status; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Login request
	 * @param {*} context 
	 * @param {object} user_data 
	 */
	user_login( context, user_data ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 

            // check user email or token
            if( ! user_data.user_mail && ! user_data.token ) {
                // set rejection
				return reject({message: 'Your email address is missing.'});
            }

            // check user password or token
            if( ! user_data.user_pass && ! user_data.token ) {
                // set rejection
				return reject({message: 'You do not seem to have entered a password. Did you forget it?'});
            }
			
			// load data via API
			ApiService.post('user/login', user_data, function( data ) {
				
				// save data to localstorage
				JwtService.setData( data );

				// set data
				context.commit('USER_SET_DATA', data); 
                context.commit('USER_SET_LOGINSTATUS', true);

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Logout
	 * @param {*} context 
	 */
	user_logout( context ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 

			// remove user
            JwtService.destroyData();
            JwtService.destroyData('product');

			// set data
			context.commit('USER_SET_DATA', {}); 
			context.commit('USER_SET_LOGINSTATUS', false);

			// load support
			context.dispatch( 'support_unload' ).then(() => {});

			// set success
			return resolve();

		});

	},

	/**
	 * Create User
	 * @param {*} context 
	 * @param {object} user_data 
	 */
	user_create( context, user_data ) {
	
		// call api to create user
		return new Promise( function(resolve, reject) { 

            // check user name
            if( ! user_data.user_mail ) {
                // set rejection
				return reject({message: 'Please enter an email address. It is required for you to be able to log in.'});
            }

            // check user email
            if( ! user_data.user_name ) {
                // set rejection
				return reject({message: 'We really need your name. How else are you supposed to be identified?'});
            }

            // check user password
            if( ! user_data.user_pass ) {
                // set rejection
				return reject({message: 'Without a password everyone would have access to your account. You do not want that, do you?'});
            }

			// load data via API
			ApiService.post('user/create', user_data, function( data ) {
				
				// save data to localstorage
				JwtService.setData( data );

				// set data
				context.commit('USER_SET_DATA', data); 
				context.commit('USER_SET_LOGINSTATUS', true);

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},

	/**
	 * Edit User
	 * @param {*} context 
	 * @param {object} user_edit 
	 */
	user_edit( context, user_edit ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.update('user', state.user_data.ID, user_edit, function( data ) {

				// load new data
				var new_data = JwtService.getData();
				new_data.user_data = data;

				// save data to localstorage
				JwtService.setData( new_data );

				// set data
				context.commit('USER_SET_DATA', new_data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},

	/**
	 * Remove User
	 * @param {*} context 
	 */
	user_remove( context ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('user/' + state.user_data.ID, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },
    
    /**
	 * Upload a user image
	 * @param {*} context 
	 * @param {object} name file 
	 */
	user_image( context, {name, file} ) {

        // call api to get users
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.upload('/user/' + state.user_data.ID + '/image', name, file, function( data ) {

                // load new data
				var new_data = JwtService.getData();
				new_data.user_data = data;

				// save data to localstorage
				JwtService.setData( new_data );

				// set data
				context.commit('USER_SET_DATA', new_data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

    /**
	 * Delete a user image
	 * @param {*} context 
	 */
	user_image_remove( context ) {

        // call api to get users
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('/user/' + state.user_data.ID + '/image', function( data ) {

                // load new data
				var new_data = JwtService.getData();
				new_data.user_data = data;

				// save data to localstorage
				JwtService.setData( new_data );

				// set data
				context.commit('USER_SET_DATA', new_data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},

	// reset an user
	user_reset( context, user_data ) {
        
        // call api to reset user
		return new Promise( function(resolve, reject) { 

            // check user email
            if( ! user_data.user_pass ) {
                // set rejection
				return reject({message: 'Please enter a new password'});
            }

            // check user email
            if( ! user_data.user_key ) {
                // set rejection
				return reject({message: 'Missing key'});
            }
			
			// load data via API
			ApiService.post('user/reset', user_data, function( data ) {
				
				// save data to localstorage
				JwtService.setData( data );

				// set data
				context.commit('USER_SET_DATA', data); 
                context.commit('USER_SET_LOGINSTATUS', true);

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });
        
    },
    
    // create an user
	user_reset_req( context, user_data ) {
        
        // call api to reset user
		return new Promise( function(resolve, reject) { 

            // check user email
            if( ! user_data.user_mail ) {
                // set rejection
				return reject({message: 'Your email address is missing.'});
            }
			
			// load data via API
			ApiService.post('user/reset/req', user_data, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},
	
	/**
	 * Oauth request
	 * @param {*} context 
	 * @param {object} service 
	 */
	user_oauth( context, service ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 
			
			// load data via API
			ApiService.post('oauth/key', {service: service}, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},
	
	/**
	 * Callback request
	 * @param {*} context 
	 * @param {object} callback_data 
	 */
	user_callback( context, callback_data ) {
		
		// call api to create user
		return new Promise( function(resolve, reject) { 
			
			// load data via API
			ApiService.post('callback', callback_data, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get login status
	user_is_loggedin(state) {
		return state.user_is_loggedin;
	},

	// get user data
	user_data(state) {
		return state.user_data;
	},

	// get user data
	user_id(state) {
		return state.user_data.ID;
	},

	// get user token
	user_token(state) {
		return state.user_token;
	},
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};