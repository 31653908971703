import Settings from "@/common/general-settings";

/**
 * **************************
 * General:
 * store data
 * **************************
 */

const StoreService = {


    get( name ) {

        // get store
        var store = this.get_store();

        // get
        if( store == 'ls' ) {
            // use localstorage
            return this.localstorage_get( name );
        } else if( store == 'ck' ) {
            // use cookie
            return this.cookie_get( name );
        } else if( store == 'ts' ) {
            // use temp storage
            return this.tempstore_get( name );
        }

    },


    set( name, data ) {

        // get store
        var store = this.get_store();

        // get
        if( store == 'ls' ) {
            // use localstorage
            return this.localstorage_set( name, data );
        } else if( store == 'ck' ) {
            // use cookie
            return this.cookie_set( name, data );
        } else if( store == 'ts' ) {
            // use temp storage
            return this.tempstore_set( name, data );
        }

    },


    remove( name ) {
        
        // get store
        var store = this.get_store();

        // get
        if( store == 'ls' ) {
            // use localstorage
            return this.localstorage_remove( name );
        } else if( store == 'ck' ) {
            // use cookie
            return this.cookie_remove( name );
        } else if( store == 'ts' ) {
            // use temp storage
            return this.tempstore_remove( name );
        }

    },

    /**
     * Read data from local storage
     * @param {*} lsname 
     */
    localstorage_get( lsname ) {

        // try localstorage
        try {
            // check if data exist
            if( window.localStorage.getItem(lsname) ) {
                // return data
                return JSON.parse( window.localStorage.getItem(lsname) );
            }
        } catch (e) {
            return false;
        }

        // return on no data
        return false;

    },

    /**
     * Set data to local storage
     * @param {*} lsname 
     * @param {*} lsvalue 
     */
    localstorage_set( lsname, lsvalue ) {
        
        // try localstorage
        try {
            // write data to local storage
            window.localStorage.setItem( lsname, JSON.stringify(lsvalue) );
        } catch (e) {
            return false;
        }

    },

    /**
     * Remove data from local storage
     * @param {*} lsname 
     */
    localstorage_remove( lsname ) {
        
        // try localstorage
        try {
            // remove data from local storage
            window.localStorage.removeItem(lsname);
        } catch (e) {
            return false;
        }

    },

    /**
     * Read data from cookie
     * @param {*} cname 
     */
    cookie_get( cname ) {

        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return JSON.parse( c.substring(name.length, c.length) );
            }
        }
        return false;

    },

    /**
     * Set data in cookie
     * @param {*} cname 
     * @param {*} cvalue 
     * @param {*} exdays 
     */
    cookie_set( cname, cvalue, exdays = 90 ) {

        // validate
        try{

            // do action
            var jvalue = cvalue;
            jvalue = encodeURIComponent(JSON.stringify(jvalue));
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            var path = "path=/";
            var domain = "domain=." + Settings.get('cookie_domain');
            document.cookie = cname + "=" + jvalue + ";" + expires + ";" + path + ";" + domain;

        } catch(e) {
            return false;
        }

    },

    /**
     * Remove cookie
     * @param {*} cname 
     */
    cookie_remove( cname ) {

        // validate
        try{

            // do action
            var jvalue = JSON.stringify({});
            var d = new Date();
            d.setTime(d.getTime() + ((-20)*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            var path = "path=/";
            var domain = "domain=." + Settings.get('cookie_domain');
            document.cookie = cname + "=" + jvalue + ";" + expires + ";" + path + ";" + domain;

        } catch(e) {
            return false;
        }

    },

    /**
     * Read data from fallback store
     * @param {*} cname 
     */
    tempstore_get( tsname ) {

        // validate
        try{
            if( window.tempLocalStorage[tsname] ) {
                // dummy storage
                return JSON.parse( window.tempLocalStorage[tsname] );
            }
        } catch(e) {
            return false;
        }

        return false;

    },

    /**
     * Set data in fallback store
     * @param {*} cname 
     * @param {*} cvalue 
     * @param {*} exdays 
     */
    tempstore_set( tsname, tsvalue ) {

        // validate
        try{
            // dummy storage
            window.tempLocalStorage[tsname] = JSON.stringify(tsvalue);
        } catch(e) {
            return false;
        }

    },

    /**
     * Remove fallback store
     * @param {*} cname 
     */
    tempstore_remove( tsname ) {

        // validate
        try{
            // dummy storage
            delete window.tempLocalStorage[lsname];
        } catch(e) {
            return false;
        }

    },

    /**
     * Get the store to use
     * prefered ck => cookie, ls => localstorage
     */
    get_store() {

        // check if we have already set an storage service
        if( this.setStorage ) return this.setStorage;
        
        // check if we are already using local storage
        if( this.defaultToken !== false && this.localstorage_get( this.defaultToken ) !== false ) return 'ls';

        // check if we are already using cookie
        if( this.defaultToken !== false && this.cookie_get( this.defaultToken ) !== false ) return 'ck';
        
        // get prefered
        var prefered        = ((this.preferedStorage) ? this.preferedStorage : 'ls' );

        // check if cookies enabled
        var cookie_enabled  = navigator.cookieEnabled;

        // check if local storage is availible
        this.localstorage_set( '_sleek_test_sleekplan', {data:true} );
        var localstorage_available = (( this.localstorage_get( '_sleek_test_sleekplan' ) !== false ) ? true : false );
        this.localstorage_remove( '_sleek_test_sleekplan' );

        // if we want to use cookies as prefered or local storage is not availible
        if( prefered == 'ck' || localstorage_available == false ) {
            // check if cookies are availible
            this.cookie_set( '_sleek_test_sleekplan', {data:true} );
            var cookie_available = (( this.cookie_get( '_sleek_test_sleekplan' ) !== false ) ? true : false );
            this.cookie_remove( '_sleek_test_sleekplan' );
        }

        // check if local storage is availible because this is our default choice
        if( localstorage_available === true ) {
            // if local storage is availible but we want to use cookie as prefered
            if( prefered == 'ck' ) {
                // check if cookie is availible
                if( cookie_available === true && cookie_enabled === true ) {
                    // set storage
                    this.setStorage = 'ck';
                    // return cookie
                    return 'ck';
                }
            }
            // set storage
            this.setStorage = 'ls';
            // return local storage
            return 'ls';
        // if lcoal storage is not availible test with cookies
        } else if ( cookie_available === true && cookie_enabled === true ) {
            // set storage
            this.setStorage = 'ck';
            // return cookie
            return 'ck';
        // if cookie is also not availible return error
        } else {
            // init 
            window.tempLocalStorage = new Object();
            // set storage
            this.setStorage = 'ts';
            // return local storage
            return 'ts';
        }

    },

    /**
     * Set preferred storage
     * @param {ls, ck} preferedStorage 
     */
    init( default_token = false, preferedStorage = 'ls' ) {

        // set preferred storage
        this.preferedStorage    = preferedStorage;

        // set default token
        this.defaultToken       = default_token;

    }

};

export default StoreService;