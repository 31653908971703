/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
	notification_items: {}
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Add notification
	NOTIFICATION_ADD( state, notifications ) { state.notification_items = notifications; },

	// Remove notification
	NOTIFICATION_REMOVE( state, notifications ) { state.notification_items = notifications; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Login request
	 * @param {*} context 
	 * @param {object} notification_data 
	 */
	notification_add( context, notification_data ) {

        // load current notifications
        var notifications = Object.assign({}, state.notification_items);
        // add new notification
        notifications[notification_data.name] = notification_data;

        // commit changes
        context.commit('NOTIFICATION_ADD', notifications);

        setTimeout( function(){ context.dispatch( 'notification_remove', notification_data.name ); }, 5000 )

    },

    /**
	 * Login request
	 * @param {*} context 
	 * @param {object} notification_data 
	 */
	notification_remove( context, notification_name ) {

        // load current notifications
        var notifications = Object.assign({}, state.notification_items);
        // add new notification
        delete notifications[notification_name];

        // commit changes
        context.commit('NOTIFICATION_REMOVE', notifications);

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get noticiations
	notifications(state) {
		return state.notification_items;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};