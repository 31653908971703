import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    widget_survey: false
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // set survey
    WIDGET_SET_SURVEY( state, data ) { state.widget_survey = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Get survey
	 * @param {*} query
	 */
    widget_survey_get( context, survey_id ) {

        // call api
		return new Promise( function(resolve, reject) { 
            
            // load data via API
            ApiService.get('feedback/' + context.getters.product_active_id + '/survey', survey_id, function( data ) {

                // set survey data
                context.commit('WIDGET_SET_SURVEY', data); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });
        
    },

    /**
	 * Collect survey response
	 * @param {*} query
	 */
    widget_survey_collect( context, {survey_id, response_id, response} ) {

        // call api to get feedback items
		return new Promise( function(resolve, reject) {

            // update feedback via api
            ApiService.post('feedback/' + context.getters.product_active_id + '/survey/' + survey_id + '/collect/' + response_id, response, function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });
        
    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get satisfaction vote
	widget_survey(state) {
		return state.widget_survey;
    },
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};