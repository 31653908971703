import Vue from 'vue'
import Router from 'vue-router'
import home from './views/home.vue'
import Settings from './common/general-settings';

// load Router
Vue.use(Router)

// Define Router
const router_object = new Router({
    linkExactActiveClass: 'nav-item-active',
    mode: (( Settings.get('type') === 'embedded' ) ? 'hash' : 'history' ),
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: false, expandable: false, button: false},
            component: home
        },
        {
            path: '/inactive',
            name: 'inactive',
            meta: {requiresAuth: true, transition: false, header: false, footer: true, back: false, expandable: false, button: true},
            component: () => import('./views/inactive.vue')
        },
        {
            path: '/feedback',
            name: 'feedback',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: true},
            component: () => import('./views/feedback.vue')
        },
        {
            path: '/feedback/add',
            name: 'feedback-add',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/feedback-add.vue')
        },
        {
            path: '/feedback/edit/:id',
            name: 'feedback-edit',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/feedback-add.vue')
        },
        {
            path: '/feedback/:id',
            name: 'feedback-item',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: true},
            component: () => import('./views/feedback-item.vue')
        },
        {
            path: '/changelog',
            name: 'changelog',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: {text: 'hb_subscribe', icon: 'ios-notifications-outline', dependency: 'product_settings.general.subscribe_changelog'}},
            component: () => import('./views/changelog.vue')
        },
        {
            path: '/changelog/:id',
            name: 'changelog-item',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: true, button: false},
            component: () => import('./views/changelog-item.vue')
        },
        {
            path: '/roadmap',
            name: 'roadmap',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: true},
            component: () => import('./views/roadmap.vue')
        },
        {
            path: '/account',
            name: 'account',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/account.vue')
        },
        {
            path: '/notifications',
            name: 'notifications',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/notifications.vue')
        },
        {
            path: '/satisfaction',
            name: 'satisfaction',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/satisfaction.vue')
        },
        {
            path: '/promoter',
            name: 'promoter',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/promoter.vue')
        },
        {
            path: '/survey/:id?',
            name: 'survey',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/survey.vue')
        },
        {
            path: '/iframe',
            name: 'iframe',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/iframe.vue')
        },
        {
            path: '/popup/satisfaction/:id?',
            name: 'popup-satisfaction',
            meta: {requiresAuth: true, transition: false, header: false, footer: false, back: false, expandable: false, button: false},
            component: () => import('./views/popup-satisfaction.vue')
        },
        {
            path: '/popup/promoter/:id?',
            name: 'popup-promoter',
            meta: {requiresAuth: true, transition: false, header: false, footer: false, back: false, expandable: false, button: false},
            component: () => import('./views/popup-promoter.vue')
        },
        {
            path: '/popup/feedback/:id?',
            name: 'popup-feedback',
            meta: {requiresAuth: true, transition: false, header: false, footer: false, back: false, expandable: false, button: false},
            component: () => import('./views/popup-feedback.vue')
        },
        {
            path: '/popup/changelog/:id?',
            name: 'popup-changelog',
            meta: {requiresAuth: true, transition: false, header: false, footer: false, back: false, expandable: false, button: false},
            component: () => import('./views/popup-changelog.vue')
        },
        {
            path: '/popup/update/:id?',
            name: 'popup-update',
            meta: {requiresAuth: true, transition: false, header: false, footer: false, back: false, expandable: false, button: false},
            component: () => import('./views/popup-update.vue')
        },
        {
            path: '/popup/survey/:id?',
            name: 'popup-survey',
            meta: {requiresAuth: true, transition: false, header: false, footer: false, back: false, expandable: false, button: false},
            component: () => import('./views/popup-survey.vue')
        },
        {
            path: '/tiny/feedback',
            name: 'tiny-feedback',
            meta: {requiresAuth: true, transition: false, header: false, footer: false, back: false, expandable: false, button: false},
            component: () => import('./views/tiny-feedback.vue')
        },
        {
            path: '/tiny/changelog',
            name: 'tiny-changelog',
            meta: {requiresAuth: true, transition: false, header: false, footer: false, back: false, expandable: false, button: false},
            component: () => import('./views/tiny-changelog.vue')
        },
        {
            path: '/custom/:id',
            name: 'custom',
            meta: {requiresAuth: true, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/custom.vue')
        },
        {
            path: '*',
            name: '404',
            meta: {requiresAuth: false, transition: true, header: true, footer: true, back: true, expandable: false, button: false},
            component: () => import('./views/error.vue')
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            // if we have no saved position for that page
            if ( ! savedPosition ) {
                // load at very top
                return resolve({ x: 0, y: 0 });
            }
            // in case we have a saved position
            setTimeout(() => {
                // load that saved position
                return resolve(savedPosition);
            // while waiting for animation to start
            }, 450);
        })
    }
})

// Handle Browser History
router_object.beforeEach((to, from, next) => {  
    // get top offset to use later
    var top_offset = ((document.documentElement.scrollTop) ? document.documentElement.scrollTop : window.scrollY);
    // Standalone => Keep Histroy
    if( Settings.get('type') == 'standalone' ) {
        // allow route
        next();
    // Widget => Get rid of Histroy
    } else {
        // check if we need to replace that route
        if( to.meta.replace !== true ) {
            // load current route object
            var replace_url = Object.assign({}, to);
            // add replace meta for check
            replace_url.meta.replace = true;
            // set to replace history stack
            replace_url.replace = true;
            // laod!
            next(replace_url);
            // reset replace
            to.meta.replace = false;
        }
        else next() // allow route
    }
    // Check if is from a page where we want to save the position
    if( 
        from.name == 'home' || 
        from.name == 'feedback' ||
        from.name == 'changelog'
    ) {
        // set top offset
        from.meta.position_top = top_offset;
    }
});

// Export router Object
export default router_object;