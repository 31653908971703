<template>
    <div class="inner" :class="{expanded: widget_sync.expanded}">

        <!-- Left menu -->
        <div class="secondary-menu" v-if="$route.meta.back !== false">
            <a href="javascript:void(0)" v-on:click="route_back()">
                <div class="menu-item menu-action-back">
                    <ion-icon name="ios-arrow-back"></ion-icon>
                </div>
            </a>
        </div>

        <!-- Left menu -->
        <div class="context-menu">
            <div v-if="widget_data_header && widget_data_header.title">
                <img v-if="widget_data_header && widget_data_header.image" :src="widget_data_header.image">
                <span>{{ widget_data_header.title }} <span v-if="widget_data_header && widget_data_header.description"><br>{{ widget_data_header.description }}</span></span>
            </div>
        </div>

        <!-- Right menu => general -->
        <div class="main-menu" v-if="$route.name == 'home'">
            <router-link :to="{ name: 'feedback' }" v-if="product_data.product_settings.widget.enable_feedback !== false && this.product_data.product_settings.general.module_feedback && main_module !== 'feedback' && main_module !== 'actions'">
                <div class="menu-item has-text counter menu-action-feedback" :class="show_menu_item('feedback')">
                    <span>{{ ((product_data.product_settings.widget.terms.feedback && product_data.product_settings.widget.terms.feedback.length > 0) ? product_data.product_settings.widget.terms.feedback : $t("header_feedback") ) }}</span> 
                    <ion-icon name="ios-list"></ion-icon>
                </div>
            </router-link>
            <router-link :to="{ name: 'changelog' }" v-if="product_data.product_settings.widget.enable_changelog !== false && this.product_data.product_settings.general.module_changelog && main_module !== 'changelog' && main_module !== 'actions'">
                <div class="menu-item has-text counter menu-action-changelog" :class="show_menu_item('changelog')">
                    <div class="item-counter" v-if="widget_sync.notifications_updates_count > 0">
                        <span>{{widget_sync.notifications_updates_count}}</span>
                    </div>
                    <span>{{ ((product_data.product_settings.widget.terms.changelog && product_data.product_settings.widget.terms.changelog.length > 0) ? product_data.product_settings.widget.terms.changelog : $t("header_changelog") ) }}</span> 
                    <ion-icon name="ios-list"></ion-icon>
                </div>
            </router-link>
            <router-link :to="{ name: 'roadmap' }" v-if="product_data.product_settings.widget.enable_roadmap !== false && this.product_data.product_settings.general.module_roadmap && main_module !== 'roadmap' && main_module !== 'actions'">
                <div class="menu-item has-text menu-action-changelog" :class="show_menu_item('roadmap')">
                    <span>{{ ((product_data.product_settings.widget.terms.roadmap && product_data.product_settings.widget.terms.roadmap.length > 0) ? product_data.product_settings.widget.terms.roadmap : $t("header_roadmap") ) }}</span> 
                    <ion-icon name="ios-map"></ion-icon>
                </div>
            </router-link>
            <router-link :to="{ name: 'notifications' }" v-if="user && product_data.product_settings.widget.enable_notification !== false">
                <div class="menu-item counter menu-action-notifications">
                    <div class="item-counter" v-if="notifications_count > 0">
                        <span>{{notifications_count}}</span>
                    </div>
                    <ion-icon name="ios-notifications"></ion-icon>
                </div>
            </router-link>
            <!-- Close Widget -->
            <div class="menu-item menu-action-close" v-on:click="close_widget">
                <ion-icon name="close"></ion-icon>
            </div>
        </div>

        <!-- Right menu => custom button -->
        <div class="main-menu has-custom-btn" v-else-if="$route.meta.button !== false && typeof $route.meta.button == 'object'">
            <div class="menu-item full-text menu-action-custom" v-on:click="button_press" v-if="!$route.meta.button.dependency || ($route.meta.button.dependency && check_dependency(product_data, $route.meta.button.dependency) === true)">
                <div class="text">{{$t(button.text)}}</div>
                <div>
                    <ion-icon v-if="button_is_loading == false" :name="button.icon"></ion-icon>
                    <img v-if="button_is_loading == true" src="@/assets/media/svg/loading-light.svg">
                </div>
            </div>
            <!-- Expand Widget -->
            <div v-if="$route.meta.expandable === true" class="menu-item menu-action-expansion" v-on:click="expansion_toggle">
                <ion-icon name="expand" v-if="widget_sync.expanded === false"></ion-icon>
                <ion-icon name="contract" v-else-if="widget_sync.expanded === true"></ion-icon>
            </div>
            <!-- Close Widget -->
            <div class="menu-item menu-action-close" v-on:click="close_widget">
                <ion-icon name="close"></ion-icon>
            </div>
        </div>

        <!-- Right menu => default button -->
        <div class="main-menu has-custom-btn" v-else-if="$route.meta.button === true">
            <router-link :to="{ name: 'feedback-add' }" v-if="product_data.product_settings.widget.enable_submit && $route.name !== 'home' && product_data.product_settings.general.module_feedback == true">
                <div class="menu-item has-text menu-action-feedback" :class="show_menu_item('feedback')">
                    <span>{{ ((product_data.product_settings.widget.text.post_button && product_data.product_settings.widget.text.post_button.length > 0) ? product_data.product_settings.widget.text.post_button : $t("header_feedback") ) }}</span> 
                    <ion-icon name="ios-add"></ion-icon>
                </div>
            </router-link>
            <router-link :to="{ name: 'notifications' }" v-if="user && product_data.product_settings.widget.enable_notification !== false">
                <div class="menu-item counter menu-action-notifications">
                    <div class="item-counter" v-if="notifications_count > 0">
                        <span>{{notifications_count}}</span>
                    </div>
                    <ion-icon name="ios-notifications"></ion-icon>
                </div>
            </router-link>
            <!-- Expand Widget -->
            <div v-if="$route.meta.expandable === true" class="menu-item menu-action-expansion" v-on:click="expansion_toggle">
                <ion-icon name="expand" v-if="widget_sync.expanded === false"></ion-icon>
                <ion-icon name="contract" v-else-if="widget_sync.expanded === true"></ion-icon>
            </div>
            <!-- Close Widget -->
            <div class="menu-item menu-action-close" v-on:click="close_widget">
                <ion-icon name="close"></ion-icon>
            </div>
        </div>

        <!-- Right menu => no button -->
        <div class="main-menu has-custom-btn" v-else>
            <router-link :to="{ name: 'notifications' }" v-if="user && product_data.product_settings.widget.enable_notification !== false">
                <div class="menu-item counter menu-action-notifications">
                    <div class="item-counter" v-if="notifications_count > 0">
                        <span>{{notifications_count}}</span>
                    </div>
                    <ion-icon name="ios-notifications"></ion-icon>
                </div>
            </router-link>
            <!-- Expand Widget -->
            <div v-if="$route.meta.expandable === true" class="menu-item menu-action-expansion" v-on:click="expansion_toggle">
                <ion-icon name="expand" v-if="widget_sync.expanded === false"></ion-icon>
                <ion-icon name="contract" v-else-if="widget_sync.expanded === true"></ion-icon>
            </div>
            <!-- Close Widget -->
            <div class="menu-item menu-action-close" v-on:click="close_widget">
                <ion-icon name="close"></ion-icon>
            </div>
        </div>

    </div>
</template>

<script>
import WidgetSync from '@/common/widget-sync.js';

export default {

    name: 'app-header',


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {

        return {

            // feedback vars
            button_is_loading: false,

            // histoy vars
            history_stack: [],
            history_to: false,
            history_skip: [
                'feedback/add'
            ],

            // button
            button: this.$route.meta.button,

            // notifications
            notification_timestamp: Date.now(),
            notification_cooldown: 60

        }

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        product_data() {
            return this.$store.getters.product_data;
        },

        user() {
            return this.$store.getters.user_data;
        },

        notifications_count() {
            return this.$store.getters.widget_notifications_count;
        },

        widget_sync() {
            return this.$store.getters.widget_sync;
        },

        main_module() {

            // init empty main module
            var main_module = '';

            // load active module
            if( this.product_data.product_settings.widget.home === 'feedback' && this.product_data.product_settings.general.module_feedback == true ) {
                main_module = 'feedback';
            } else if( this.product_data.product_settings.widget.home === 'feedback' && this.product_data.product_settings.general.module_feedback !== true ) {
                main_module = 'changelog';
            } else if( this.product_data.product_settings.widget.home === 'changelog' && this.product_data.product_settings.general.module_changelog == true ) {
                main_module = 'changelog';
            } else if( this.product_data.product_settings.widget.home === 'changelog' && this.product_data.product_settings.general.module_changelog !== true ) {
                main_module = 'feedback';
            } else if( this.product_data.product_settings.widget.home === 'roadmap' ) {
                main_module = 'roadmap';
            } else if( this.product_data.product_settings.widget.home === 'actions' ) {
                main_module = 'actions';
            }

            // return module
            return main_module;

        },

        widget_data_header() {
            return this.$store.getters.widget_data_header;
        }

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    methods: {

        /**
         *  Trigger button press
         */
        button_press() {

            // send event to eventHub
            this.$eventHub.$emit('header-button-pressed');

        },

        /**
         *  On close widget
         */
        close_widget() {

            // trigger widget close in parent
            WidgetSync.close();

        },

        /**
         *  Load notifications count
         */
        load_notifications_count() {

            // load feedback item
            this.$store.dispatch( 'widget_notifications_count' )
            .then((data) => {})
            .catch(e => {});

        },

        /**
         *  Update notifications count
         */
        update_notifications_count() {

            // set grace period and current timestamp
            var notification_grace_period       = this.notification_timestamp + (this.notification_cooldown*1000);
            var notification_current_timestamp  = Date.now();

            // check if we should refresh notifications count
            if( notification_grace_period < notification_current_timestamp ) {
                // update notifications count
                this.load_notifications_count();
                // set last updated timestamp to now
                this.notification_timestamp = notification_current_timestamp;
            }

        },

        /**
         *  Load notifications count
         */
        check_dependency(obj, prop) {

            if(typeof obj === 'undefined') {
                return false;
            }

            var _index = prop.indexOf('.')
            if(_index > -1) {
                return this.check_dependency(obj[prop.substring(0, _index)], prop.substr(_index + 1));
            }

            return obj[prop];

        },

        /**
         *  Load expansion
         */
        expansion_load( route ) {

            // check if route is expandable
            if( route.meta.expandable === true ) {
                // trigger expand via SDK
                WidgetSync.expandView( true, false );
            // in all other cases
            } else {
                // trigger collapse via SDK
                WidgetSync.expandView( false, false );
            }

        },

        /**
         *  Toggle widget expansion
         */
        expansion_toggle() {

            // set expanded state
            var expand_state = ((this.widget_sync.expanded === true) ? false : true);

            // invert expansion via SDK
            WidgetSync.expandView( expand_state, true );

        },

        /**
         *  Show menu item
         */
        show_menu_item( type ) {

            // if is feedback
            if( type === 'feedback' ) {}

            // if is changelog
            if( type === 'changelog' ) {
                if( this.$route.name  !== 'home' ) {
                    return '';
                }
            }

            // if is changelog
            if( type === 'roadmap' ) {
                if( this.$route.name  !== 'home' ) {
                    return '';
                }
            }

            // return full
            return 'full';
            
        },

        /**
         *  On back button
         */
        route_back() {

            // if we have a boolean
            if( typeof this.$route.meta.back === "boolean" && this.$route.meta.back === true ) {

                // trigger back click
                this.$route.meta.is_back = true;

                // set history from
                this.history_to = this.history_stack.pop();

                // make sure we have a history item
                if( this.history_to !== undefined ) {
                    // load all elements to skip
                    this.history_skip.forEach((element) => {
                        // check if history_to contains element we want to skip
                        if( this.history_to.includes(element) ) {
                            // unset history to
                            this.history_to = undefined;
                        }
                    });
                }

                // if we have no history item
                if( this.history_to === undefined ) {
                    // go back
                    this.$router.push({ name: 'home' }); 
                }

                // go back
                this.$router.push({ path: this.history_to }); 
                
            } else if( typeof this.$route.meta.back === "string" ) {

                // trigger back click
                this.$route.meta.is_back = true;
                
                // go back
                this.$router.push({ path: this.$route.meta.back }); 

            }

        }

    },


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted() {

        // load button pressed
        this.$eventHub.$on('header-button-loading', function() {
            // toggle button_is_loading status
            this.button_is_loading = ! this.button_is_loading;
        }.bind(this) );

        // update button
        this.$eventHub.$on('header-button-update', function( button_data ) {
            // load updated data
            this.button = button_data;
        }.bind(this) );

        // initial load notifications count
        this.load_notifications_count();

        // load expansion
        this.expansion_load( this.$route );

    },


    /** ----------------------------------------------------
     * View => On Destroyed
     ----------------------------------------------------- */
    destroyed() {

        // stop button press
        this.$eventHub.$off('header-button-loading');

    },


    /** ----------------------------------------------------
     * Watch => route
     ----------------------------------------------------- */
    watch: {
        // watch router changes
        '$route' (to, from) {

            // load expansion
            this.expansion_load( to );
            
            // skip history stack
            if( typeof from.meta.back === "string" ) {
                // update button
                this.button = this.$route.meta.button;
                // stop here
                return true;
            }

            // check history from
            if( this.history_to !== to.fullPath ) {
                // add to history stack
                this.history_stack.push( from.fullPath );
            }

            // reset histroy from
            this.history_from = false;

            // update button
            this.button = this.$route.meta.button;

            // check if we have a saved top offset <= Fallback solution if is not native back button
            if( typeof to == 'object' && typeof to.meta == 'object' && typeof to.meta.position_top == 'number' ) {
                // -> 450 ms <-> transition time
                // create an interval of 200 ms
                this.position_interval = setInterval(() => {
                    // set saved position to top offset
                    window.scrollTo( 0, to.meta.position_top );
                // each 200ms
                }, 200);
                // wait for the transition to complete
                setTimeout(() => {
                    clearInterval(this.position_interval);
                }, 800); // reset interval at 800ms
            }

            // update notifications
            this.update_notifications_count();

        }
    }

}
</script>