import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	product_tags: [],
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set tags
	PRODUCT_TAGS_SET( state, data ) { state.product_tags = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

	/**
	 * Load tags
	 * @param {*} context 
	 */
	product_tags_load( context, force = false ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

			// check if item exists
            if( state.product_tags.length > 0 && force !== true ) {

                // set success
                return resolve( state.tags );

			}

            // load data via API
			ApiService.get('feedback/' + context.getters.product_active_id, 'tags', function( data ) {

				// set data
				context.commit('PRODUCT_TAGS_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	}

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

    // get tags
	product_tags(state) {
		return state.product_tags;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};