/**
 * **************************
 * Helper:
 * Widget sync wrapper
 * **************************
 */

const WidgetSync = {

    close() {
        // if no $sleek SDK defined
        if( this.sync_check_parent_access() !== true || window.parent.$sleek === undefined ) {
            return false;
        }
        // close widget via $sleek SDK
        window.parent.$sleek.close();
    },

	trigger( event_id, event_data = {} ) {
        // if no $sleek SDK defined
        if( this.sync_check_parent_access() !== true || window.parent.$sleek === undefined ) {
            return false;
        }
        // push trigger via $sleek SDK
        window.parent.$sleek.push_trigger( event_id, event_data );
    },

    auth( callback ) {
        // if no $sleek SDK defined
        if( this.sync_check_parent_access() !== true || window.parent.$sleek === undefined ) {
            return callback( ((window.SLEEK_SETTINGS && window.SLEEK_SETTINGS.auth) ? window.SLEEK_SETTINGS.auth : false ) );
        }
        // load callback from $sleek SDK
        window.parent.$sleek.push_auth( callback );
    },

    expandView( state, update = false ) {
        // if no $sleek SDK defined
        if( this.sync_check_parent_access() !== true || window.parent.$sleek === undefined ) {
            return false;
        }
        // load expandView from $sleek SDK
        window.parent.$sleek.expandView( state, update );
    },

    sync_check_parent_access() {
        // try if parent document is reachable
        try{
            parent.document;
            // return true in this case
            return true;
        } catch {
            // otherwise return false
            return false;
        }
    }

}
    
export default WidgetSync;