import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Set promoter vote
	 * @param {*} query
	 */
    widget_promoter_vote( context, vote_data ) {

        // call api to get feedback items
		return new Promise( function(resolve, reject) {

            // set promoter vote via api
            ApiService.post('feedback/' + context.getters.product_active_id + '/promoter/', vote_data, function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });
        
    },

    /**
	 * Set promoter comment
	 * @param {*} query
	 */
    widget_promoter_comment( context, comment_data ) {

        // call api to get feedback items
		return new Promise( function(resolve, reject) {

            // update promoter comment via api
            ApiService.put('feedback/' + context.getters.product_active_id + '/promoter/' + comment_data.promoter_id + '/comment', {comment: comment_data.comment}, function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });
        
    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};