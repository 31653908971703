import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {

    widget_changelog_subscription: false,

};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set notifications
    WIDGET_CHANGELOG_SUBSCRIPTION_SET( state, data ) { state.widget_changelog_subscription = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Get subscription status
	 * @param {*} context 
	 * @param {object} feedback_id 
	 */
	widget_changelog_subscription( context ) {
		
		// call api to get feedback items
		return new Promise( function(resolve, reject) {

            // update feedback via api
            ApiService.get('feedback/' + context.getters.product_active_id + '/changelog_subscription/', '', function( data ) {

                // set sbscription status
                context.commit('WIDGET_CHANGELOG_SUBSCRIPTION_SET', data.status); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });

    },
	
	/**
	 * Subscribe
	 * @param {*} context 
	 * @param {object} feedback_id 
	 */
	widget_changelog_subscribe( context ) {
		
		// call api to get feedback items
		return new Promise( function(resolve, reject) {

            // update feedback via api
            ApiService.post('feedback/' + context.getters.product_active_id + '/changelog_subscription/', {}, function( data ) {

                // set sbscription status
                context.commit('WIDGET_CHANGELOG_SUBSCRIPTION_SET', true); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });

    },
    
    /**
	 * Unsubscribe
	 * @param {*} context 
	 * @param {object} params 
	 */
	widget_changelog_unsubscribe( context ) {
		
		// call api to get feedback items
		return new Promise( function(resolve, reject) {

            // update feedback via api
            ApiService.delete('feedback/' + context.getters.product_active_id + '/changelog_subscription/', function( data ) {

                // set sbscription status
                context.commit('WIDGET_CHANGELOG_SUBSCRIPTION_SET', false); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });

	}


};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

    // get feedback notificationss
	widget_changelog_subscription(state) {
		return state.widget_changelog_subscription;
    },

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};