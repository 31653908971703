import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    widget_sync: {
        // default settings
        expanded: false
    }
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // set sync
    WIDGET_SET_SYNC( state, data ) { state.widget_sync = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Set data from widget
	 * @param {*} sync_data
	 */
    widget_set_data( context, sync_data = {} ) {

        // USER DATA => if is user data && user is loggedin
        if( sync_data.user && context.getters.user_data ) {
            // call update user methode
            context.dispatch( 'widget_user_edit', sync_data.user );
            // done
            return true;
        }

        // load current widget data
        var sync = Object.assign({}, context.getters.widget_sync, sync_data);
        
        // set new widget data
        context.commit('WIDGET_SET_SYNC', sync); 
        
    },

    /**
	 * Remova data from widget
	 * @param {*} sync_data
	 */
    widget_remove_data( context, key ) {

        // load current widget data
        var sync = Object.assign({}, context.getters.widget_sync);

        // remove key
        delete sync[key];

        // set new widget data
        context.commit('WIDGET_SET_SYNC', sync); 
        
    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get sync vote
	widget_sync(state) {
		return state.widget_sync;
    },
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};