import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Subscribe
	 * @param {*} context 
	 * @param {object} feedback_id 
	 */
	feedback_subscribe( context, feedback_id ) {
		
		// call api to get feedback items
		return new Promise( function(resolve, reject) {

            // update feedback via api
            ApiService.post('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/subscribe/', {}, function( data ) {

                // get single product
                context.dispatch( 'feedback_item_load', {feedback_id: feedback_id, force: true} );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });

    },
    
    /**
	 * Unsubscribe
	 * @param {*} context 
	 * @param {object} params 
	 */
	feedback_unsubscribe( context, feedback_id ) {
		
		// call api to get feedback items
		return new Promise( function(resolve, reject) {

            // update feedback via api
            ApiService.post('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/unsubscribe/', {}, function( data ) {

                // get single product
                context.dispatch( 'feedback_item_load', {feedback_id: feedback_id, force: true} );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });

	}


};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};