import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/general-jwt";
import { API_URL } from "@/common/config";

/**
 * **************************
 * General:
 * Api management
 * **************************
 */

const ApiService = {

	// initally load ApiService
	init() {

		// initall load Axios for Vue
		Vue.use(VueAxios, axios);

		// get jwt data
		var token_data = JwtService.getData();

		// set defaults
		Vue.axios.defaults.baseURL = API_URL;
		Vue.axios.defaults.params = {};
        Vue.axios.defaults.params['access_token'] = token_data.token;

	},

	// set auth header
	setHeader() {

        // set timestamp to prevent caching
        Vue.axios.defaults.params['timestamp'] = new Date().getTime();

	},

	// check auth
	check_auth( e ) {

		// check if user requires login
		if( e.response && e.response.status === 403 ) {
			// logout user
			window.init_app.$store.dispatch( 'widget_user_logout' ).then(() => {
                // load home screen
                window.init_app.$router.push({ name: "home" });
            });
		// check if user reached rate limit
		} else if ( e.response && e.response.status === 429 ) {
			// show alert
			window.init_app.$store.dispatch( 'notification_add', { name: 'rate_alert', type: 'error', message: 'It seems you sent too many requests within a short period of time. Please try again after a while!' } );
		}

		// return
		return true;

	},

	/**
	 * HTTP (GET) QUERY
	 * @param {string} resource 
	 * @param {object} params 
	 * @param {function} success 
	 * @param {function} error 
	 */
	async query(resource, params, success = function(){}, error = function(){}) {
		// set header
		this.setHeader();
		// try/catch API request
		try {
			// build API request and wait for return
			const response = await Vue.axios.get(resource, { params: params } );
			// return successull data
			if( response.data.status == 'success' ) {
				// on sucessfull api request
				success(response.data.data);
			}
			if( response.data.status == 'error' ) {
				// on bad api request
				error(response.data.data);
			}
		} catch (e) {
			// check auth error
			this.check_auth(e);
			// return error data
		 	error(e);
		}
	},

	/**
	 * HTTP GET
	 * @param {string} resource 
	 * @param {string} slug 
	 * @param {function} success 
	 * @param {function} error 
	 */
	async get(resource, slug = "", success = function(){}, error = function(){}) {
		// set header
		this.setHeader();
		// try/catch API request
		try {
			// build API request and wait for return
			const response = await Vue.axios.get(`${resource}/${slug}`);
			// return successull data
			if( response.data.status == 'success' ) {
				// on sucessfull api request
				success(response.data.data);
			}
			if( response.data.status == 'error' ) {
				// on bad api request
				error(response.data.data);
			}
		} catch (e) {
			// check auth error
			this.check_auth(e);
			// return error data
		 	error(e);
		}
	},

	/**
	 * HTTP POST
	 * @param {string} resource 
	 * @param {object} params 
	 * @param {function} success 
	 * @param {function} error 
	 */
	async post(resource, params, success = function(){}, error = function(){}) {
		// set header
		this.setHeader();
		// try/catch API request
		try {
			// build API request and wait for return
			const response = await Vue.axios.post(`${resource}`, params);
			// return successull data
			if( response.data.status == 'success' ) {
				// on sucessfull api request
				success(response.data.data);
			}
			if( response.data.status == 'error' ) {
				// on bad api request
				error(response.data.data);
			}
		} catch (e) {
			// check auth error
			this.check_auth(e);
			// return error data
		 	error(e);
		}
	},

	/**
	 * HTTP UPDATE
	 * @param {string} resource 
	 * @param {string} slug 
	 * @param {object} params 
	 * @param {function} success 
	 * @param {function} error 
	 */
	async update(resource, slug, params, success = function(){}, error = function(){}) {
		// set header
		this.setHeader();
		// try/catch API request
		try {
			// build API request and wait for return
			const response = await Vue.axios.put(`${resource}/${slug}`, params);
			// return successull data
			if( response.data.status == 'success' ) {
				// on sucessfull api request
				success(response.data.data);
			}
			if( response.data.status == 'error' ) {
				// on bad api request
				error(response.data.data);
			}
		} catch (e) {
			// check auth error
			this.check_auth(e);
			// return error data
		 	error(e);
		}
	},

	/**
	 * HTTP PUT
	 * @param {string} resource 
	 * @param {object} params 
	 * @param {function} success 
	 * @param {function} error 
	 */
	async put(resource, params, success = function(){}, error = function(){}) {
		// set header
		this.setHeader();
		// try/catch API request
		try {
			// build API request and wait for return
			const response = await Vue.axios.put(`${resource}`, params);
			// return successull data
			if( response.data.status == 'success' ) {
				// on sucessfull api request
				success(response.data.data);
			}
			if( response.data.status == 'error' ) {
				// on bad api request
				error(response.data.data);
			}
		} catch (e) {
			// check auth error
			this.check_auth(e);
			// return error data
		 	error(e);
		}
	},

	/**
	 * HTTP DELETE
	 * @param {string} resource 
	 * @param {function} success 
	 * @param {function} error 
	 */
	async delete(resource, success = function(){}, error = function(){}) {
		// set header
		this.setHeader();
		// try/catch API request
		try {
			// build API request and wait for return
			const response = await Vue.axios.delete(resource);
			// return successull data
			if( response.data.status == 'success' ) {
				// on sucessfull api request
				success(response.data.data);
			}
			if( response.data.status == 'error' ) {
				// on bad api request
				error(response.data.data);
			}
		} catch (e) {
			// check auth error
			this.check_auth(e);
			// return error data
		 	error(e);
		}
    },
    
    /**
	 * HTTP UPLOAD
	 * @param {string} resource 
	 * @param {function} success 
	 * @param {function} error 
	 */
	async upload(resource, name, file, success = function(){}, error = function(){}) {
		// set header
        this.setHeader();
        // build form data
        const formData = new FormData();
        formData.append(name, file);
        // set header
        const local_config = { headers: { 'content-type': 'multipart/form-data' } };
		// try/catch API request
		try {
			// build API request and wait for return
			const response = await Vue.axios.post(`${resource}`, formData, local_config);
			// return successull data
			if( response.data.status == 'success' ) {
				// on sucessfull api request
				success(response.data.data);
			}
			if( response.data.status == 'error' ) {
				// on bad api request
				error(response.data.data);
			}
		} catch (e) {
			// check auth error
			this.check_auth(e);
			// return error data
		 	error(e);
		}
	}
	
};

export default ApiService;